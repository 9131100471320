import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import {
  AppBar,
  Tabs,
  Tab,
  Toolbar,
  withStyles,
} from '@material-ui/core';

const styles = () => ({
  toolbar: {
    justifyContent: 'center',
    backgroundColor: '#F3F3F3',
  },
});

const Header = (props) => {
  const {
    location: { pathname },
    match,
    classes,
  } = props;
  // we need to do some route parsing because we're not using NavLink from react router
  // this is not robust and will require some tweaking if there are ever query params
  const segment = pathname.match(/(?<=sidepane\/)[^/]+(?=\/$|$)/i);
  const to = segment ? segment[0] : '';

  if (!to) {
    return null;
  }

  return (
    <AppBar
      position="static"
      color="default"
    >
      <Toolbar
        variant="dense"
        className={classes.toolbar}
      >
        <Tabs
          value={to}
          indicatorColor="secondary"
          textColor="secondary"
          fullWidth
          style={{ textAlign: 'center' }}
        >
          <Tab label="News" value="news" component={Link} to={`${match.url}/news`} disableRipple disableTouchRipple />
          <Tab label="Community" value="community" component={Link} to={`${match.url}/community`} disableRipple disableTouchRipple />
          <Tab label="Blog" value="blog" component={Link} to={`${match.url}/blog`} disableRipple disableTouchRipple />
        </Tabs>
      </Toolbar>
    </AppBar>
  );
};

export default withRouter(withStyles(styles)(Header));
