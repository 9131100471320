import React, { Component } from 'react';
import YouTube from 'react-youtube';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  IconButton,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Close, OpenInBrowser } from '@material-ui/icons';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

const styles = () => ({
  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  titleText: {
    flex: '1 1 auto',
  },
});

class VideoPlayBackDialogContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      player: {},
    };
    this.onOpenInBrowser = this.onOpenInBrowser.bind(this);
    this.onReady = this.onReady.bind(this);
  }

  // what is this for?
  // eslint-disable-next-line class-methods-use-this
  onReady(event) {
    event.target.pauseVideo();
    this.setState({
      player: event.target,
    });
  }

  onOpenInBrowser() {
    const { videoId } = this.props;
    const { player } = this.state;
    player.pauseVideo();
    window.location.assign(`https://www.youtube.com/watch?time_continue=${Math.floor(player.getCurrentTime())}&v=${videoId}`);
  }

  render() {
    const {
      classes,
      width,
      videoId,
      title,
      description,
      open,
      onClose,
    } = this.props;

    const opts = {
      height: isWidthDown('md', width) ? '337.5' : '540',
      width: isWidthDown('md', width) ? '600' : '960',
      playerVars: {
        autoplay: 0,
        rel: 0,
      },
    };

    return (
      <Dialog
        onClose={onClose}
        open={open}
        fullWidth
        maxWidth={isWidthDown('md', width) ? 'sm' : 'md'}
        aria-labelledby="video-dialog-title"
        aria-describedby="video-dialog-description"
      >
        <DialogTitle id="video-dialog-title" disableTypography={false}>
          <div className={classes.title}>
            <Typography variant="h5" className={classes.titleText}>
              {title}
            </Typography>
            <IconButton
              onClick={this.onOpenInBrowser}
              size="small"
            >
              <OpenInBrowser />
            </IconButton>
            <IconButton
              onClick={onClose}
              size="small"
            >
              <Close />
            </IconButton>
          </div>
        </DialogTitle>
        <YouTube
          videoId={videoId}
          opts={opts}
          onReady={this.onReady}
        />
        <DialogContent style={{ marginTop: '10px' }}>
          <DialogContentText id="video-dialog-description">
            {description}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
  }
}

export default withStyles(styles)(withWidth()(VideoPlayBackDialogContainer));
