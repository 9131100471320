import React from 'react';
import { withRouter } from 'react-router-dom';
import { IconButton, Tooltip } from '@material-ui/core';
import { StarRounded, StarBorderRounded } from '@material-ui/icons';

const BookmarkRouteButton = (props) => {
  const {
    isBookmarkMode,
    history,
    match: { params },
  } = props;

  return (
    <Tooltip title="Favorites" placement="bottom">
      <IconButton
        onClick={() => {
          if (isBookmarkMode) {
            history.push(`/${params.lang}/tutorial`);
          } else {
            history.push(`/${params.lang}/tutorial/bookmarks`);
          }
        }}
        color={isBookmarkMode ? 'secondary' : 'default'}
        style={isBookmarkMode ? { backgroundColor: '#373737' } : {}} // darker background when bookmark button is active
      >
        {isBookmarkMode ? <StarRounded /> : <StarBorderRounded />}
      </IconButton>
    </Tooltip>
  );
};

export default withRouter(BookmarkRouteButton);
