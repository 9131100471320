import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, Typography, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import VideoPlaylistContainer from '../container/tutorial/VideoPlaylistContainer';
import VideoSearchContainer from '../container/tutorial/VideoSearchContainer';

const styles = () => ({
  bookmarkToolbar: {
    display: 'flex',
    height: 50,
    width: '100%',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  title: {
    flexGrow: 1,
  },
});

class TutorialLandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFeaturedMode: true,
    };
  }

  render() {
    const { viewMode, classes } = this.props;
    const { isFeaturedMode } = this.state;

    return (
      <Grid container direction="column" spacing={16}>
        <Grid item>
          <div className={classes.bookmarkToolbar}>
            <Typography variant="h4" className={classes.title}>{isFeaturedMode ? 'Featured tutorials:' : 'All tutorials:'}</Typography>
            <Button color="primary" onClick={() => { this.setState({ isFeaturedMode: true }); }} disabled={isFeaturedMode}>Featured tutorials</Button>
            <Typography variant="body1">|</Typography>
            <Button color="primary" onClick={() => { this.setState({ isFeaturedMode: false }); }} disabled={!isFeaturedMode}>All tutorials</Button>
          </div>
        </Grid>
        <Grid item>
          {isFeaturedMode
            ? <VideoPlaylistContainer viewMode={viewMode} />
            : <VideoSearchContainer query="" viewMode={viewMode} />}
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(withRouter(TutorialLandingPage));
