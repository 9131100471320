import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import ListItemLink from './ListItemLink';

const CommunityPost = (props) => {
  const {
    title, link, excerpt, author, date,
  } = props;
  const dateOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  const postDate = new Date(date);
  const linkURL = new URL(link);
  const sourceVersion = window.location.hostname.replace('.wilcomhs.com', '');
  
  linkURL.searchParams.append('utm_campaign', 'software');
  linkURL.searchParams.append('utm_medium', 'tab_community');
  linkURL.searchParams.append('utm_source', sourceVersion);

  return (
    <ListItemLink href={linkURL.href}>
      <Grid container direction="column" spacing={8}>
        <Grid item xs={12}>
          <Typography variant="h6" align="left" color="primary" gutterBottom>
            {title}
          </Typography>
          <Typography variant="body2" align="left" gutterBottom paragraph>
            {excerpt}
          </Typography>
          <Grid container direction="row">
            <Grid item xs={6}>
              <Typography variant="subtitle2" align="left" paragraph>
                {`By: ${author}`}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2" align="right" paragraph>
                {`Posted: ${postDate.toLocaleDateString('en-US', dateOptions)}`}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ListItemLink>
  );
};

export default CommunityPost;
