import React from 'react';
import VideoGridLayout from './VideoGridLayout';
import VideoListLayout from './VideoListLayout';

const VideoLayoutSwitcher = (props) => {
  const {
    viewMode,
    videos,
    onClick,
    onAddBookmark,
    onRemoveBookmark,
  } = props;

  switch (viewMode) {
    case 'grid':
      return (
        <VideoGridLayout
          videos={videos}
          onClick={onClick}
          onAddBookmark={onAddBookmark}
          onRemoveBookmark={onRemoveBookmark}
        />
      );
    case 'list':
      return (
        <VideoListLayout
          videos={videos}
          onClick={onClick}
          onAddBookmark={onAddBookmark}
          onRemoveBookmark={onRemoveBookmark}
        />
      );
    default:
      return null;
  }
};

export default VideoLayoutSwitcher;
