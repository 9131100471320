import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Grid, GridList, GridListTile, GridListTileBar, Button,
} from '@material-ui/core';
import axios from 'axios';
import WilcomLoader from '../component/WilcomLoader';

const styles = theme => ({
  container: {
    width: '100%',
    maxWidth: '500px',
  },
  gridList: {
    display: 'flex',
    width: '100%',
    alignContent: 'flex-start',
    padding: '10px',
  },
  root: {
    cursor: 'pointer',
    '&:hover $image': {
      filter: 'brightness(65%)',
    },
  },
  image: {
    filter: 'brightness(100%)',
    transition: 'filter 0.5s',
  },
  tileBarTopRootLarge: {
    display: 'flex',
    alignItems: 'flex-start',
    height: theme.typography.pxToRem(144),
    paddingTop: '20px',
    background:
      'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, '
      + 'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    fontFamily: 'Open Sans, Roboto, Tahoma',
  },
  tileBarTopRootSmall: {
    display: 'flex',
    alignItems: 'flex-start',
    height: theme.typography.pxToRem(83),
    paddingTop: '20px',
    background:
      'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, '
      + 'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    fontFamily: 'Open Sans, Roboto, Tahoma',
  },
  tileBarTopTitleLarge: {
    fontSize: theme.typography.pxToRem(32),
    paddingBottom: '10px',
    lineHeight: 1,
    textOverflow: 'clip',
    overflow: 'visible',
    whiteSpace: 'normal',
    fontWeight: 'normal',
    textShadow: '1px 1px 2px rgba(0,0,0,0.3)',
  },
  tileBarTopTitleSmall: {
    fontSize: theme.typography.pxToRem(18),
    lineHeight: '1.2em',
    textOverflow: 'clip',
    overflow: 'visible',
    whiteSpace: 'normal',
    textShadow: '1px 1px 2px rgba(0,0,0,0.3)',
  },
  tileBarTopSubtitle: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1,
    textOverflow: 'clip',
    overflow: 'visible',
    whiteSpace: 'normal',
    textShadow: '1px 1px 2px rgba(0,0,0,0.3)',
  },
  tileBarBot: {
    height: theme.typography.pxToRem(68),
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, ' 
      + 'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  catButton: {
    color: 'white',
    marginRight: '16px',
    borderRadius: 3,
  },
});

class MarketingBannerContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      banners: [],
      isLoading: true,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    axios
      .get('https://s3.amazonaws.com/ESe4/WilcomESHome/News.txt', {
        headers: {
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache', // ensure news.txt is not cached by browser
          Pragma: 'no-cache',
        },
      })
      .then((res) => {
        this.setState({
          banners: res.data,
          isLoading: false,
        });
      })
      .catch(() => {
        this.setState({
          banners: [],
          isLoading: false,
        });
      });
  }

  render() {
    const { classes } = this.props;
    const { banners, isLoading } = this.state;

    if (isLoading) {
      return <WilcomLoader loading={isLoading} />;
    }

    return (
      <Grid item className={classes.container}>
        <GridList cellHeight={180} spacing={4} cols={2} className={classes.gridList}>
          {banners.map((tile, idx) => {
            const isFeatured = idx === 0;

            const MoreLinkURL = new URL(tile.MoreLink);
            const sourceVersion = window.location.hostname.replace('.wilcomhs.com', '');

            MoreLinkURL.searchParams.append('utm_campaign', 'software');
            MoreLinkURL.searchParams.append('utm_medium', 'tab_news');
            MoreLinkURL.searchParams.append('utm_source', sourceVersion);

            return (
              <GridListTile
                key={MoreLinkURL.href}
                className={classes.root}
                cols={isFeatured ? 2 : 1}
                rows={isFeatured ? 2 : 1}
                onClick={() => {
                  window.location = MoreLinkURL.href;
                }}
              >
                <img className={classes.image} src={tile.BackgroundImage} alt={tile.Title} />
                <GridListTileBar
                  title={tile.Title}
                  subtitle={isFeatured && tile.Content}
                  titlePosition="top"
                  classes={{
                    root: isFeatured ? classes.tileBarTopRootLarge : classes.tileBarTopRootSmall,
                    title: isFeatured ? classes.tileBarTopTitleLarge : classes.tileBarTopTitleSmall,
                    subtitle: classes.tileBarTopSubtitle,
                  }}
                />
                <GridListTileBar
                  titlePosition="bottom"
                  actionIcon={(
                    <Button
                      size={isFeatured ? 'large' : 'small'}
                      variant="contained"
                      color="primary"
                      href={MoreLinkURL.href}
                      className={classes.catButton}
                    >
                      {tile.MoreButtonLabel || 'Read More'}
                    </Button>
                  )}
                  actionPosition="right"
                  className={classes.tileBarBot}
                />
              </GridListTile>
            );
          })}
        </GridList>
      </Grid>
    );
  }
}

export default withStyles(styles)(MarketingBannerContainer);
