import React, { Component } from 'react';
import VideoPlaybackDialogContainer from './VideoPlaybackDialogContainer';

// this HOC takes a component and provides videoplayback
// lifecycle functions to support embedded video playback
function withVideoPlaybackDialog(WrappedComponent) {
  return class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        selectedVideo: {
          id: '',
          title: '',
          description: '',
        },
        playerIsOpen: false,
      };
    }

    handlePlayVideo = (videoId, videoTitle, videoDescription) => {
      this.setState({
        selectedVideo: {
          id: videoId,
          title: videoTitle,
          description: videoDescription,
        },
        playerIsOpen: true,
      });
    }

    handleClosePlaybackDialog = () => {
      this.setState({
        playerIsOpen: false,
      });
    }

    render() {
      const {
        selectedVideo,
        playerIsOpen,
      } = this.state;
      return (
        <>
          <WrappedComponent onPlayVideo={this.handlePlayVideo} {...this.props} />
          <VideoPlaybackDialogContainer
            videoId={selectedVideo.id || ''}
            title={selectedVideo.title || ''}
            description={selectedVideo.description || ''}
            open={playerIsOpen}
            onClose={this.handleClosePlaybackDialog}
          />
        </>
      );
    }
  };
}

export default withVideoPlaybackDialog;
