import React from 'react';
import {
  Switch, Route, Redirect, BrowserRouter as Router,
} from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';

import ESTheme from './themes/ESTheme';
import App from './App';

// by default we use the 'light' theme
const theme = ESTheme('light');

// load environment variables
require('dotenv').config();

const supportedLang = [
  'en', // English
  'nl', // Dutch
  'fr', // French
  'de', // German
  'it', // Italian
  'ja', // Japanese
  'ko', // Korean
  'pt', // Portugese
  'ru', // Russian
  'es', // Spanish
  'sv', // Swedish
];

const AppWrapper = () => (
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    <Router>
      <Switch>
        <Redirect exact from="/" to="/en/sidepane" />
        {/* should only allow supported languages in the future */}
        <Route path={`/:lang(${supportedLang.join('|')})?`} component={App} />
      </Switch>
    </Router>
  </MuiThemeProvider>
);

export default AppWrapper;
