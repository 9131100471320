import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import Header from '../container/Header';
import SidePaneContentContainer from '../container/SidePaneContentContainer';
import BlogContainer from '../container/BlogContainer';
import CommunityContainer from '../container/CommunityContainer';
import NewsPage from './NewsPage';

const SidePaneRoutes = ({ match }) => (
  <>
    <Header />
    <SidePaneContentContainer>
      <Switch>
        <Route exact path={`${match.path}/community`} component={CommunityContainer} />
        <Route exact path={`${match.path}/blog`} component={BlogContainer} />
        <Route exact path={`${match.path}/news`} component={NewsPage} />
        <Redirect from={`${match.path}*`} to={`${match.path}/news`} />
      </Switch>
    </SidePaneContentContainer>
  </>
);

export default SidePaneRoutes;
