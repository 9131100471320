import low from 'lowdb';
import LocalStorage from 'lowdb/adapters/LocalStorage';
import Memory from 'lowdb/adapters/Memory';

export const db = low(
  process.env.NODE_ENV === 'test'
    ? new Memory()
    : new LocalStorage('tutorial'),
);
const dbName = 'bookmarks';

// by default, we have empty array
db.defaults({
  bookmarks: [],
})
  .write();

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

// finds videos with query in either title or description.
export function find(query) {
  const safeQuery = escapeRegExp(query);
  const queryRe = new RegExp(safeQuery, 'gi');

  return db.get(dbName)
    .filter((vid) => {
      const titleMatch = vid.title.match(queryRe);
      const descrMatch = vid.description.match(queryRe);
      return (titleMatch || descrMatch);
    })
    .sortBy(['title'])
    .value();
}

// Lists all videos in order of title
export function listAll() {
  return db.get(dbName)
    .sortBy(['title'])
    .value();
}

// check whether a video exists in database by its id.
export function hasById(videoId) {
  return db.get(dbName)
    .find({ id: videoId })
    .value() !== undefined;
}

// Checks if videoId is already in db, if yes => we update
// otherwise => we push
// Technically, there's no reason to believe the video would
// already be in the db as we remove on unbookmark, but just in case...
// WARNING: there's no data validation here, client beware!
export function insertVideo(video) {
  const exists = hasById(video.id);

  if (exists) {
    return db.get(dbName)
      .find({ id: video.id })
      .assign(video)
      .write();
  }
  return db.get(dbName)
    .push(video)
    .write();
}

// Delete a video from the database based on videoId.
export function removeById(videoId) {
  return db.get(dbName)
    .remove({ id: videoId })
    .write();
}
