import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import VideoBookmarkContainer from '../container/tutorial/VideoBookmarkContainer';
import TutorialBookmarkSearchBoxContainer from '../container/tutorial/TutorialBookmarkSearchBoxContainer';

const styles = () => ({
  bookmarkToolbar: {
    display: 'flex',
    height: 50,
    width: '100%',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  title: {
    flexGrow: 1,
  },
});

class TutorialBookmarkPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
    };
  }

  handleBookmarkQueryChange = (newQuery) => {
    this.setState({
      query: newQuery,
    });
  }

  render() {
    const { viewMode, classes } = this.props;
    const { query } = this.state;

    return (
      <Grid container direction="column" spacing={16}>
        <Grid item>
          <div className={classes.bookmarkToolbar}>
            <Typography variant="h4" className={classes.title}>Favorites:</Typography>
            <TutorialBookmarkSearchBoxContainer
              query={query}
              onBookmarkQueryChange={this.handleBookmarkQueryChange}
            />
          </div>
        </Grid>
        <Grid item>
          <VideoBookmarkContainer
            query={query}
            viewMode={viewMode}
          />
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(withRouter(TutorialBookmarkPage));
