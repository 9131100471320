import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { IconButton, Divider, Grid, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { ViewList, ViewModule } from '@material-ui/icons';
import QueryString from 'query-string';
import BookmarkRouteButton from '../../component/tutorial/BookmarkRouteButton';
import TutorialChannelSearchBoxContainer from './TutorialChannelSearchBoxContainer';

const styles = theme => ({
  toolbar: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-around',
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    marginBottom: 10,
  },
  searchBox: {
    flexGrow: 1,
    marginRight: 10, // a little margin from the buttons
  },
});

// eslint-disable-next-line react/prefer-stateless-function
class TutorialToolBar extends Component {
  render() {
    const {
      classes,
      match,
      viewMode,
      onSwitchView,
    } = this.props;

    return (
      <Grid container spacing={8} direction="column">
        <Grid item className={classes.toolbar}>
          <div className={classes.searchBox}>
            <Switch>
              <Route
                exact
                path={`${match.path}/search`}
                render={(routeProps) => {
                  const { location: { search } } = this.props;
                  const query = QueryString.parse(search).q;
                  return (
                    <TutorialChannelSearchBoxContainer
                      {...routeProps}
                      query={query}
                    />
                  );
                }}
              />
              <Route
                render={routeProps => (
                  <TutorialChannelSearchBoxContainer
                    {...routeProps}
                    query=""
                  />
                )}
              />
            </Switch>
          </div>
          <div>
            <Tooltip
              title={viewMode === 'grid' ? 'List View' : 'Grid View'}
              placement="bottom"
            >
              <IconButton onClick={onSwitchView}>
                {viewMode === 'grid' ? <ViewList /> : <ViewModule />}
              </IconButton>
            </Tooltip>
            <Switch>
              <Route
                exact
                path={`${match.path}/bookmarks`}
                render={routeProps => (
                  <Tooltip title="Favorites" placement="bottom">
                  <BookmarkRouteButton
                    {...routeProps}
                    isBookmarkMode
                  />
                  </Tooltip>
                )}
              />
              <Route
                render={routeProps => (
                  <BookmarkRouteButton
                    {...routeProps}
                    isBookmarkMode={false}
                  />
                )}
              />
            </Switch>
          </div>
        </Grid>
        <Divider />
      </Grid>
    );
  }
}

export default withStyles(styles)(withRouter(TutorialToolBar));
