import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import { StarRounded, StarBorderRounded } from '@material-ui/icons';

const VideoBookmarkButton = (props) => {
  const {
    videoId,
    isBookmarked,
    onAddBookmark,
    onRemoveBookmark,
  } = props;

  return (
    <Tooltip
      title={isBookmarked ? 'Remove from Favorites' : 'Set as Favorite'}
      placement="bottom"
    >
      <IconButton onClick={
        isBookmarked
          ? e => onRemoveBookmark(videoId, e)
          : e => onAddBookmark(videoId, e)}
      >
        {isBookmarked
          ? <StarRounded />
          : <StarBorderRounded />}
      </IconButton>
    </Tooltip>
  );
};

export default VideoBookmarkButton;
