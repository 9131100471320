import React from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { Grid, CssBaseline, withStyles } from '@material-ui/core';
import ESTheme from '../../themes/ESTheme';

const darkESTheme = ESTheme('dark');

const styles = () => ({
  root: {
    height: '100%',
    width: '100%',
    padding: 20,
  },
  container: {
    width: '100%',
    maxWidth: '960px', // limit the width on larger screens
    margin: 8,
  },
  '@global': {
    body: {
      overflowY: 'scroll', // want the scroll bar to always appear even with no overflow
      '&::-webkit-scrollbar': {
        width: '1em',
      },
      '&::-webkit-scrollbar-button': {
        height: '1em',
        width: '1em',
        background: 'no-repeat #5e5e5e',
        backgroundSize: '1em',
        backgroundPosition: 'center bottom',
        '&:hover': {
          background: 'no-repeat #3a3a3a',
          backgroundSize: '1em',
          backgroundPosition: 'center bottom',
        },
        '&:active': {
          background: 'no-repeat #232323',
          backgroundSize: '1em',
          backgroundPosition: 'center bottom',
        },
        '&:single-button': {
          '&:vertical': {
            '&:decrement': {
              backgroundImage: 'url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\' width=\'100\' height=\'100\'><polyline points=\'30,60 50,40 70,60\' stroke=\'%23fff\' fill=\'none\' stroke-width=\'4\' /></svg>")',
            },
            '&:increment': {
              backgroundImage: 'url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\' width=\'100\' height=\'100\'><polyline points=\'30,40 50,60 70,40\' stroke=\'%23fff\' fill=\'none\' stroke-width=\'4\' /></svg>")',
            },
          },
          '&:horizontal': {
            '&:decrement': {
              backgroundImage: 'url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\' width=\'100\' height=\'100\'><polyline points=\'60,30 40,50 60,70\' stroke=\'%23fff\' fill=\'none\' stroke-width=\'4\' /></svg>")',
            },
            '&:increment': {
              backgroundImage: 'url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\' width=\'100\' height=\'100\'><polyline points=\'40,30 60,50 40,70\' stroke=\'%23fff\' fill=\'none\' stroke-width=\'4\' /></svg>")',
            },
          },
        },
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#5698e2',
        '&:hover': {
          background: '#3386E2',
        },
        '&:active': {
          background: '#1475e0',
        },
      },
      '&::-webkit-scrollbar-track': {
        background: '#5e5e5e',
      },
      '&::-webkit-scrollbar-corner': {
        background: '#5e5e5e',
      },
    },
  },
});

const TutorialContentContainer = (props) => {
  const { classes, children } = props;
  return (
    <MuiThemeProvider theme={darkESTheme}>
      <CssBaseline />
      <Grid container className={classes.root} justify="center">
        <Grid item className={classes.container}>
          {children}
        </Grid>
      </Grid>
    </MuiThemeProvider>
  );
};

export default withStyles(styles)(TutorialContentContainer);
