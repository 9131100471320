import React, { Component } from 'react';
import {
  Grid, List, Divider, IconButton,
} from '@material-ui/core';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import axios from 'axios';
import _ from 'lodash';
import WilcomLoader from '../component/WilcomLoader';
import BlogPost from '../component/BlogPost';

const Entities = require('html-entities').AllHtmlEntities;
const Striptags = require('striptags');

class BlogContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
      isLoading: true,
    };
  }

  componentDidMount() {
    this.fetchPosts();
  }

  fetchPosts() {
    const maxExcerptCharCount = 200;
    axios
      .get('https://productblog.wilcom.com/wp-json/wp/v2/posts?_embed=true')
      .then((res) => {
        const entities = new Entities();

        res.data.forEach((item) => {
          // replace html encodings in title
          // eslint-disable-next-line no-param-reassign
          item.title.rendered = entities.decode(item.title.rendered);
          // strip html tags and replace html encodings for ellipse
          // eslint-disable-next-line no-param-reassign
          item.excerpt.rendered = entities.decode(Striptags(item.excerpt.rendered));

          const contentSnippet = item.excerpt.rendered;
          const excerptRe = new RegExp(`^.{1,${maxExcerptCharCount}}(?=\\s)`, 'gs');
          // eslint-disable-next-line no-param-reassign
          item.excerpt.rendered = contentSnippet.length < maxExcerptCharCount
            ? contentSnippet
            : excerptRe.exec(contentSnippet);
          // eslint-disable-next-line no-param-reassign
          item.excerpt.rendered
            += item.excerpt.rendered.length === contentSnippet.length ? '' : ' [...]';
        });
        this.setState({
          posts: res.data,
          isLoading: false,
        });
      })
      .catch(() => {
        this.setState({
          isLoading: false,
        });
      });
  }

  render() {
    const { isLoading, posts } = this.state;

    if (isLoading) {
      return <WilcomLoader loading={isLoading} />;
    }

    return (
      <>
        <Grid item xs={12}>
          <Grid container justify="flex-end" alignItems="center" style={{ paddingRight: 8 }}>
            <IconButton component="a" href="https://productblog.wilcom.com">
              <OpenInBrowserIcon />
            </IconButton>
          </Grid>
        </Grid>
        <List style={{ paddingTop: 2 }}>
          <Divider />
          {posts.map(post => (
            <li key={_.get(post, 'id', '')}>
              <BlogPost
                /* eslint no-underscore-dangle: ["error", { "allow": ["_embedded"] }] */
                title={_.get(post, 'title.rendered', 'Missing Title')}
                content={_.get(post, 'excerpt.rendered', 'Missing Content')}
                author={_.get(post, '_embedded.author[0].name', 'Missing Name')}
                link={_.get(post, 'link', '')}
                date={_.get(post, 'date', '')}
                commentCount={_.get(post, '_embedded.replies[0].length', 0)}
                thumbnailLink={_.get(post, '_embedded["wp:featuredmedia"][0].media_details.sizes.thumbnail.source_url', '')}
                thumbnailWidth={_.get(post, '_embedded["wp:featuredmedia"][0].media_details.sizes.thumbnail.width', '')}
                thumbnailHeight={_.get(post, '_embedded["wp:featuredmedia"][0].media_details.sizes.thumbnail.height', '')}
              />
              <Divider />
            </li>
          ))}
        </List>
      </>
    );
  }
}

export default BlogContainer;
