import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, Typography, Chip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import QueryString from 'query-string';
import VideoSearchContainer from '../container/tutorial/VideoSearchContainer';

const styles = () => ({
  titleBar: {
    display: 'flex',
    flexWrap: 'wrap',
    height: 50,
    alignItems: 'center',
  },
  chip: {
    marginLeft: 15,
  },
});

class TutorialSearchPage extends Component {
  handleClearQuery = () => {
    const { history, match: { params } } = this.props;
    history.push(`/${params.lang}/tutorial`);
  }

  render() {
    const {
      classes,
      location: { search },
      viewMode,
    } = this.props;
    const query = QueryString.parse(search).q;
    return (
      <Grid container direction="column" spacing={16}>
        <Grid item className={classes.titleBar}>
          <Typography variant="h4">Search results:</Typography>
          <Chip
            label={query}
            color="primary"
            onDelete={this.handleClearQuery}
            variant="outlined"
            className={classes.chip}
          />
        </Grid>
        <Grid item>
          <VideoSearchContainer
            query={query}
            viewMode={viewMode}
          />
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(withRouter(TutorialSearchPage));
