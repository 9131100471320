import React, { Component } from 'react';
import { Typography } from '@material-ui/core';
import InfiniteScroll from 'react-infinite-scroll-component';
import * as TutorialAPI from '../../service/TutorialAPI';
import VideoLayoutSwitcher from '../../component/tutorial/VideoLayoutSwitcher';
import WilcomLoader from '../../component/WilcomLoader';
import withVideoPlaybackDialog from './withVideoPlaybackDialog';

const videosPerSearchFetch = 36;

class VideoSearchContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videos: [],
      nextPageToken: '',
      hasMoreItems: true,
      isLoading: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    // Store query in state so we can compare when props change.
    // Clear out previously-loaded data (so we don't render stale stuff).
    if (props.query !== state.prevQuery) {
      return {
        videos: [],
        prevQuery: props.query,
      };
    }

    // No state update necessary
    return null;
  }

  componentDidMount() {
    // eslint-disable-next-line react/destructuring-assignment
    this.fetchVideos(this.props.query);
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps) {
    // eslint-disable-next-line react/destructuring-assignment
    if (this.props.query !== prevProps.query) {
      // eslint-disable-next-line react/destructuring-assignment
      this.fetchVideos(this.props.query);
    }
  }

  fetchVideos = (query) => {
    const { nextPageToken, isLoading } = this.state;
    this.setState({
      // HACK: additional isLoading flag to false to prevent duplicated
      // fetch calls, while we execute the current call.
      // Currently this will occur when we navigate "back" to the page.
      // Both a componentDidMount and the a scroll event will trigger
      // fetchVideos, causing two concurrent api requests and display
      // the results twice.
      isLoading: true,
    });
    if (!isLoading) {
      TutorialAPI.fetchChannel(videosPerSearchFetch, query, nextPageToken)
        .then((data) => {
          this.setState((state) => {
            const videos = state.videos.concat(data.items);
            const hasMoreItems = !!data.nextPageToken; // test truthy
            return {
              videos,
              nextPageToken: hasMoreItems ? data.nextPageToken : '',
              hasMoreItems,
              isLoading: false,
            };
          });
        })
        .catch(() => {
          this.setState({
            hasMoreItems: false,
            isLoading: false,
          });
        });
    }
  }

  handleAddBookmark = (videoId) => {
    this.setState((prevState) => {
      const { videos } = prevState;
      return ({
        videos: videos.map((vid) => {
          if (vid.id === videoId) {
            TutorialAPI.addBookmark(vid);
            return {
              ...vid,
              isBookmarked: true,
            };
          }
          return vid;
        }),
      });
    });
  }

  handleRemoveBookmark = (videoId) => {
    this.setState((prevState) => {
      const { videos } = prevState;
      TutorialAPI.removeBookmark(videoId);
      return ({
        videos: videos.map((vid) => {
          if (vid.id === videoId) {
            return {
              ...vid,
              isBookmarked: false,
            };
          }
          return vid;
        }),
      });
    });
  }

  render() {
    const {
      query,
      viewMode,
      onPlayVideo,
    } = this.props;
    const {
      videos,
      hasMoreItems,
      isLoading,
    } = this.state;

    if (!isLoading && !hasMoreItems && videos.length === 0) {
      return (
        <Typography variant="body1" align="center" style={{ margin: '20%' }}>{`No results for "${query}"`}</Typography>
      );
    }

    return (
      <>
        <InfiniteScroll
          dataLength={videos.length} // This is important field to render the next data
          next={this.fetchVideos}
          hasMore={hasMoreItems}
          loader={<WilcomLoader loading={hasMoreItems} />}
          style={{ overflow: 'hidden' }}
        >
          <VideoLayoutSwitcher
            videos={videos}
            viewMode={viewMode}
            onClick={onPlayVideo}
            onAddBookmark={this.handleAddBookmark}
            onRemoveBookmark={this.handleRemoveBookmark}
          />
        </InfiniteScroll>
      </>
    );
  }
}

export default withVideoPlaybackDialog(VideoSearchContainer);
