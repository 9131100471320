import React from 'react';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import OfflineSnackbar from './OfflineSnackbar';

const styles = () => ({
  container: {
    display: 'flex',
    overflow: 'auto',
    width: '100%',
    alignContent: 'flex-start',
    justifyContent: 'center',
    height: 'calc(100% - 48px)',
  },
  '@global': {
    'html, body, #root': {
      height: '100%',
    },
  },
});

const SidePaneContentContainer = (props) => {
  const { classes, children } = props;
  return (
    <Grid container className={classes.container}>
      {children}
      <OfflineSnackbar />
    </Grid>
  );
};

export default withStyles(styles)(SidePaneContentContainer);
