import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { InputAdornment, IconButton, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Search from '@material-ui/icons/Search';

const styles = () => ({
  box: {
    backgroundColor: '#404040',
  },
  // this is actually a bit of a hack, we're overriding
  // "private" css of TextField component in mui.
  cssFocused: {},
  cssOutlinedInput: {
    '& $notchedOutline': {
      borderColor: '#363636',
    },
    '&$cssFocused $notchedOutline': {
      borderColor: '#fff',
    },
  },
  notchedOutline: {
    border: '2px solid',
  },
});

class TutorialChannelSearchBoxContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.query,
    };
  }

  static getDerivedStateFromProps(props, state) {
    // Store query in state so we can compare when props change.
    if (props.query !== state.prevQuery) {
      return {
        value: props.query,
        prevQuery: props.query,
      };
    }

    // No state update necessary
    return null;
  }

  handleChange = (event) => {
    this.setState({ value: event.target.value });
  }

  handleSubmit = (event) => {
    const { history, match: { params } } = this.props;
    event.preventDefault();
    // eslint-disable-next-line react/destructuring-assignment
    const query = this.state.value.trim();
    if (query) {
      history.push(`/${params.lang}/tutorial/search?q=${encodeURIComponent(query)}`);
    }
  }

  render() {
    const { classes } = this.props;
    const { value } = this.state;

    return (
      <form onSubmit={this.handleSubmit}>
        <TextField
          autoComplete="off"
          id="outlined-adornment-search"
          variant="outlined"
          type="text"
          placeholder="Search all tutorials"
          value={value}
          onChange={this.handleChange}
          fullWidth
          className={classes.box}
          InputLabelProps={{
            classes: {
              focused: classes.cssFocused,
            },
          }}
          InputProps={{
            classes: {
              root: classes.cssOutlinedInput,
              focused: classes.cssFocused,
              notchedOutline: classes.notchedOutline,
            },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="search"
                  onClick={this.handleSubmit}
                >
                  {<Search />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </form>
    );
  }
}

export default withRouter(withStyles(styles)(TutorialChannelSearchBoxContainer));
