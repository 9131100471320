import React from 'react';
import {
  Grid,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import VideoBookmarkButton from './VideoBookmarkButton';

const styles = () => ({
  card: {
    display: 'flex',
  },
  details: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    height: 180,
  },
  content: {
    flexGrow: 1,
  },
  titleBar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    flex: '1 1 auto',
  },
  cover: {
    display: 'flex',
    flex: '0 0 auto',
    width: 320,
    height: 180,
  },
  contentDescription: {
    display: '-webkit-box',
    lineClamp: 4,
    boxOrient: 'vertical',
    overflow: 'hidden',
  },
});

const VideoListLayout = (props) => {
  const {
    classes,
    videos,
    onClick,
    onAddBookmark,
    onRemoveBookmark,
  } = props;

  const handleAddBookmark = (videoId, e) => {
    e.stopPropagation();
    onAddBookmark(videoId);
  };

  const handleRemoveBookmark = (videoId, e) => {
    e.stopPropagation();
    onRemoveBookmark(videoId);
  };

  if (videos.length === 0) {
    return null;
  }

  return (
    <Grid container spacing={8}>
      {videos.map(video => (
        <Grid
          item
          xs={12}
          key={video.id}
        >
          <Card className={classes.card}>
            <CardActionArea
              className={classes.details}
              disableRipple
              onClick={() => onClick(
                video.id,
                video.title,
                video.description,
              )}
            >
              <CardMedia
                className={classes.cover}
                image={video.thumbnailURL}
                title={video.title}
              />
              <CardContent className={classes.content}>
                <div className={classes.titleBar}>
                  <Typography component="h5" variant="h5" className={classes.title}>
                    {video.title}
                  </Typography>
                  <VideoBookmarkButton
                    videoId={video.id}
                    isBookmarked={video.isBookmarked}
                    onAddBookmark={handleAddBookmark}
                    onRemoveBookmark={handleRemoveBookmark}
                  />
                </div>
                <Typography variant="body1" color="textSecondary" className={classes.contentDescription}>
                  {video.description}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default withStyles(styles)(VideoListLayout);
