import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { InputAdornment, IconButton, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Search, Clear } from '@material-ui/icons';

const styles = () => ({
  box: {
    width: 300,
    backgroundColor: '#404040'
  },
  // this is actually a bit of a hack, we're overriding
  // "private" css of TextField component in mui.
  cssFocused: {},
  cssOutlinedInput: {
    '& $notchedOutline': {
      borderColor: '#363636',
    },
    '&$cssFocused $notchedOutline': {
      borderColor: '#fff',
    },
  },
  notchedOutline: {
    border: '2px solid',
  },
});

class TutorialBookmarkSearchBoxContainer extends Component {
  handleChange = (event) => {
    const { onBookmarkQueryChange } = this.props;
    onBookmarkQueryChange(event.target.value);
  }

  handleClear = () => {
    const { onBookmarkQueryChange } = this.props;
    onBookmarkQueryChange('');
  }

  handleSubmit = (event) => {
    event.preventDefault();
  }

  render() {
    const { classes, query } = this.props;

    return (
      <form onSubmit={this.handleSubmit}>
        <TextField
          autoComplete="off"
          id="outlined-adornment-search"
          variant="outlined"
          type="text"
          placeholder="Search favorites"
          value={query}
          onChange={this.handleChange}
          margin="dense"
          fullWidth
          className={classes.box}
          InputLabelProps={{
            classes: {
              focused: classes.cssFocused,
            },
          }}
          InputProps={{
            classes: {
              root: classes.cssOutlinedInput,
              focused: classes.cssFocused,
              notchedOutline: classes.notchedOutline,
            },
            startAdornment: (
              <InputAdornment position="start">
                <IconButton
                  aria-label="search"
                  onClick={this.handleSubmit}
                >
                  {<Search fontSize="small" />}
                </IconButton>
              </InputAdornment>
            ),
            endAdornment: (
              query
                ? (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="clear"
                      onClick={this.handleClear}
                    >
                      {<Clear fontSize="small" />}
                    </IconButton>
                  </InputAdornment>
                )
                : null
            ),
          }}
        />
      </form>
    );
  }
}

export default withRouter(withStyles(styles)(TutorialBookmarkSearchBoxContainer));
