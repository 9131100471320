import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import TutorialContentContainer from '../container/tutorial/TutorialContentContainer';
import TutorialToolBar from '../container/tutorial/TutorialToolBar';
import TutorialLandingPage from './TutorialLandingPage';
import TutorialSearchPage from './TutorialSearchPage';
import TutorialBookmarkPage from './TutorialBookmarkPage';

class TutorialRoutes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewMode: 'grid',
    };
  }

  handleSwitchView = () => {
    this.setState(prevState => ({
      viewMode: prevState.viewMode === 'list' ? 'grid' : 'list',
    }));
  }

  render() {
    const { match } = this.props;
    const { viewMode } = this.state;
    return (
      <TutorialContentContainer>
        <Grid container spacing={16} direction="column">
          <Grid item>
            <TutorialToolBar viewMode={viewMode} onSwitchView={this.handleSwitchView} />
          </Grid>
          <Grid item>
            <Switch>
              <Route
                exact
                path={`${match.path}/`}
                render={routeProps => (
                  <TutorialLandingPage
                    {...routeProps}
                    viewMode={viewMode}
                    onSwitchView={this.handleSwitchView}
                  />
                )}
              />
              <Route
                exact
                path={`${match.path}/search`}
                render={routeProps => (
                  <TutorialSearchPage
                    {...routeProps}
                    viewMode={viewMode}
                    onSwitchView={this.handleSwitchView}
                  />
                )}
              />
              <Route
                exact
                path={`${match.path}/bookmarks`}
                render={routeProps => (
                  <TutorialBookmarkPage
                    {...routeProps}
                    viewMode={viewMode}
                    onSwitchView={this.handleSwitchView}
                  />
                )}
              />
            </Switch>
          </Grid>
        </Grid>
      </TutorialContentContainer>
    );
  }
}

export default TutorialRoutes;
