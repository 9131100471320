import React from 'react';
import {
  GridList,
  GridListTile,
  GridListTileBar,
} from '@material-ui/core';
import VideoBookmarkButton from './VideoBookmarkButton';

const VideoGridLayout = (props) => {
  const {
    videos,
    onClick,
    onAddBookmark,
    onRemoveBookmark,
  } = props;

  const handleAddBookmark = (videoId, e) => {
    e.stopPropagation();
    onAddBookmark(videoId);
  };

  const handleRemoveBookmark = (videoId, e) => {
    e.stopPropagation();
    onRemoveBookmark(videoId);
  };

  if (videos.length === 0) {
    return null;
  }

  return (
    <GridList cellHeight={176} cols={3} spacing={8}>
      {videos.map(video => (
        <GridListTile
          key={video.id}
          onClick={() => onClick(
            video.id,
            video.title,
            video.description,
          )}
        >
          <img
            src={video.thumbnailURL}
            alt={video.title}
          />
          <GridListTileBar
            title={video.title}
            titlePosition="top"
            actionIcon={(
              <VideoBookmarkButton
                videoId={video.id}
                isBookmarked={video.isBookmarked}
                onAddBookmark={handleAddBookmark}
                onRemoveBookmark={handleRemoveBookmark}
              />
            )}
          />
        </GridListTile>
      ))}
    </GridList>
  );
};

export default VideoGridLayout;
