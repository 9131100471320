import React, { Component } from 'react';
import {
  Grid,
  List,
  Divider,
  IconButton,
} from '@material-ui/core';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import WilcomLoader from '../component/WilcomLoader';
import CommunityPost from '../component/CommunityPost';

const Parser = require('rss-parser');

const parser = new Parser();

class CommunityContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
      link: '',
      isLoading: true,
    };
  }

  componentDidMount() {
    this.fetchPosts();
  }

  fetchPosts() {
    const maxExcerptCharCount = 250;
    parser.parseURL('https://community.wilcom.com/topics/mode-topics?format=feed&type=rss')
      .then((feed) => {
        feed.items.forEach((item) => {
          const authorRe = / - by: (.+)$/g;
          // eslint-disable-next-line no-param-reassign, prefer-destructuring
          item.author = authorRe.exec(item.title)[1];
          // eslint-disable-next-line no-param-reassign
          item.title = item.title.replace(authorRe, '');

          const excerptRe = new RegExp(`^.{1,${maxExcerptCharCount}}(?=\\s)`, 'gs');
          // eslint-disable-next-line no-param-reassign
          item.excerpt = item.contentSnippet.length < maxExcerptCharCount
            ? item.contentSnippet : excerptRe.exec(item.contentSnippet);
          // eslint-disable-next-line no-param-reassign
          item.excerpt += item.excerpt.length === item.contentSnippet.length ? '' : ' [...]';
        });
        this.setState({
          posts: feed.items,
          link: feed.link,
          isLoading: false,
        });
      })
      .catch(() => {
        this.setState({
          posts: [],
          isLoading: false,
        });
      });
  }

  render() {
    const { posts, link, isLoading } = this.state;

    if (isLoading) {
      return (
        <WilcomLoader loading={isLoading} />
      );
    }

    return (
      <>
        <Grid item xs={12}>
          <Grid
            container
            justify="flex-end"
            alignItems="center"
            style={{ paddingRight: 8 }}
          >
            <IconButton
              component="a"
              href={link}
              disabled={link === ''}
            >
              <OpenInBrowserIcon />
            </IconButton>
          </Grid>
        </Grid>
        <List style={{ paddingTop: 2 }}>
          <Divider />
          {posts.map(post => (
            <div key={post.guid}>
              <CommunityPost
                title={post.title}
                link={post.link}
                excerpt={post.excerpt}
                author={post.author}
                date={post.isoDate}
              />
              <Divider />
            </div>
          ))}
        </List>
      </>
    );
  }
}

export default CommunityContainer;
