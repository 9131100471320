import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import RefreshIcon from '@material-ui/icons/Refresh';
import CloudOffIcon from '@material-ui/icons/CloudOff';

const styles = theme => ({
  bar: {
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing.unit * 2,
    },
  },
});

class OfflineSnackbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    this.setState({
      open: !navigator.onLine,
    });
  }

  handleClose() {
    this.setState({
      open: false,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  handleRefresh() {
    window.location.reload(); // FIXME: maybe forceUpdate is sufficient here?
  }

  render() {
    const { classes } = this.props;
    const { open } = this.state;
    return (
      <Snackbar
        className={classes.bar}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={open}
        onClose={this.handleClose}
        ClickAwayListenerProps={{
          mouseEvent: false,
          touchEvent: false,
        }}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={(
          <span id="message-id" style={{ display: 'flex', alignItems: 'center' }}>
            <CloudOffIcon style={{ marginRight: 10 }} />
            {'You are offline. Our products work better online.'}
          </span>
        )}
        action={[
          <IconButton
            key="refresh"
            aria-label="Refresh"
            color="inherit"
            onClick={this.handleRefresh}
          >
            <RefreshIcon />
          </IconButton>,
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={this.handleClose}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    );
  }
}

export default withStyles(styles)(OfflineSnackbar);
