import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Hidden, Typography } from '@material-ui/core';
import ListItemLink from './ListItemLink';

const BlogPost = (props) => {
  const {
    title,
    content,
    author,
    link,
    date,
    commentCount,
    thumbnailLink,
    thumbnailHeight,
    thumbnailWidth,
  } = props;
  const dateOptions = { year: 'numeric', month: 'numeric', day: 'numeric' };
  const postDate = new Date(date);
  const linkURL = new URL(link);
  const sourceVersion = window.location.hostname.replace('.wilcomhs.com', '');

  linkURL.searchParams.append('utm_campaign', 'software');
  linkURL.searchParams.append('utm_medium', 'tab_blog');
  linkURL.searchParams.append('utm_source', sourceVersion);

  return (
    <ListItemLink href={linkURL.href}>
      <Grid container direction="row" justify="flex-start" spacing={8}>
        <Hidden xsDown>
          <Grid item xs={12} sm={5} md={3} lg={2} style={{ textAlign: 'center' }}>
            <img
              alt={title}
              width={thumbnailWidth}
              height={thumbnailHeight}
              src={thumbnailLink}
              title={title}
            />
          </Grid>
        </Hidden>
        <Grid item xs={12} sm={7} md={9} lg={10}>
          <Typography variant="h6" color="primary">
            {title}
          </Typography>
          <Typography variant="body2" paragraph>
            {content}
          </Typography>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="subtitle2" align="left" paragraph gutterBottom>
                {`By: ${author}, ${postDate.toLocaleDateString('en-US', dateOptions)}`}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2" align="right" paragraph gutterBottom>
                {`Comments: ${commentCount}`}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ListItemLink>
  );
};

BlogPost.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
};

export default BlogPost;
