import React, { Component } from 'react';
import { Typography } from '@material-ui/core';
import * as TutorialAPI from '../../service/TutorialAPI';
import VideoLayoutSwitcher from '../../component/tutorial/VideoLayoutSwitcher';
import WilcomLoader from '../../component/WilcomLoader';
import withVideoPlaybackDialog from './withVideoPlaybackDialog';

class VideoBookmarkContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videos: [],
      isLoading: true,
    };
  }

  static getDerivedStateFromProps(props, state) {
    // Store query in state so we can compare when props change.
    // Clear out previously-loaded data (so we don't render stale stuff).
    if (props.query !== state.prevQuery) {
      return {
        videos: [],
        isLoading: true,
        prevQuery: props.query,
      };
    }

    // No state update necessary
    return null;
  }

  componentDidMount() {
    // eslint-disable-next-line react/destructuring-assignment
    this.fetchVideos(this.props.query);
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps) {
    // eslint-disable-next-line react/destructuring-assignment
    if (this.props.query !== prevProps.query) {
      // eslint-disable-next-line react/destructuring-assignment
      this.fetchVideos(this.props.query);
    }
  }

  fetchVideos = (query) => {
    TutorialAPI.fetchBookmarks(query)
      .then((data) => {
        this.setState({
          videos: data.items,
          isLoading: false,
        });
      })
      .catch(() => {
        this.setState({
          isLoading: false,
        });
      });
  }

  handleAddBookmark = (videoId) => {
    this.setState((prevState) => {
      const { videos } = prevState;
      return ({
        videos: videos.map((vid) => {
          if (vid.id === videoId) {
            TutorialAPI.addBookmark(vid);
            return {
              ...vid,
              isBookmarked: true,
            };
          }
          return vid;
        }),
      });
    });
  }

  handleRemoveBookmark = (videoId) => {
    this.setState((prevState) => {
      const { videos } = prevState;
      TutorialAPI.removeBookmark(videoId);
      return ({
        videos: videos.map((vid) => {
          if (vid.id === videoId) {
            return {
              ...vid,
              isBookmarked: false,
            };
          }
          return vid;
        }),
      });
    });
  }

  render() {
    const {
      query,
      viewMode,
      onPlayVideo,
    } = this.props;
    const {
      videos,
      isLoading,
    } = this.state;

    if (isLoading) {
      return (
        <WilcomLoader loading={isLoading} />
      );
    }

    if (!isLoading && videos.length === 0) {
      if (query) {
        return (
          <Typography variant="body1" align="center" style={{ margin: '20%' }}>{`No results for "${query}"`}</Typography>
        );
      }
      return (
        <Typography variant="body1" align="center" style={{ margin: '20%' }}>You have no favorites to display. To favorite a video, click the star next to it.</Typography>
      );
    }

    return (
      <VideoLayoutSwitcher
        videos={videos}
        viewMode={viewMode}
        onClick={onPlayVideo}
        onAddBookmark={this.handleAddBookmark}
        onRemoveBookmark={this.handleRemoveBookmark}
      />
    );
  }
}

export default withVideoPlaybackDialog(VideoBookmarkContainer);
