import axios from 'axios';
import _ from 'lodash';
import * as bookmarks from './BookmarkDB';

const defaultPlaylistVidsPerFetch = 36;
const defaultChannelVidsPerFetch = 36;

export function fetchPlaylist(maxResults, nextPageToken) {
  return axios.get('https://www.googleapis.com/youtube/v3/playlistItems', {
    params: {
      pageToken: nextPageToken || undefined,
      key: process.env.REACT_APP_GOOGLE_API_KEY,
      part: 'snippet,contentDetails',
      playlistId: process.env.REACT_APP_YOUTUBE_PLAYLIST_ID,
      maxResults: maxResults || defaultPlaylistVidsPerFetch,
    },
  })
    .then(res => ({
      nextPageToken: res.data.nextPageToken,
      items: res.data.items.map(item => (
        {
          id: _.get(item, 'contentDetails.videoId'),
          title: _.get(item, 'snippet.title'),
          description: _.get(item, 'snippet.description'),
          thumbnailURL: _.get(item, 'snippet.thumbnails.high.url', ''),
          isBookmarked: bookmarks.hasById(_.get(item, 'contentDetails.videoId')),
        }
      )),
    }))
    .catch((err) => {
      throw err;
    });
}

export function fetchChannel(maxResults, query, nextPageToken) {
  return axios.get('https://www.googleapis.com/youtube/v3/search', {
    params: {
      q: query || undefined,
      pageToken: nextPageToken || undefined,
      key: process.env.REACT_APP_GOOGLE_API_KEY,
      part: 'id,snippet',
      channelId: process.env.REACT_APP_YOUTUBE_CHANNEL_ID,
      maxResults: maxResults || defaultChannelVidsPerFetch,
      type: 'video',
      order: 'relevance',
    },
  })
    .then(res => ({
      nextPageToken: res.data.nextPageToken,
      items: res.data.items.map(item => (
        {
          id: _.get(item, 'id.videoId'),
          title: _.get(item, 'snippet.title'),
          description: _.get(item, 'snippet.description'),
          thumbnailURL: _.get(item, 'snippet.thumbnails.high.url', ''),
          isBookmarked: bookmarks.hasById(_.get(item, 'id.videoId')),
        }
      )),
    }))
    .catch((err) => {
      throw err;
    });
}

export function fetchBookmarks(query) {
  // call find if query is truthy, otherwise call listAll.
  return new Promise((resolve, reject) => { // is this even async???
    try {
      if (query) {
        const retData = bookmarks.find(query);
        resolve({
          items: retData.map(item => (
            {
              ...item,
              isBookmarked: true,
            }
          )),
        });
      }
      const retData = bookmarks.listAll();
      resolve({
        items: retData.map(item => (
          {
            ...item,
            isBookmarked: true,
          }
        )),
      });
    } catch (err) {
      reject(err);
    }
  });
}

export function addBookmark(video) {
  const { isBookmarked, ...woIsBookmarked } = video; // remove the isBookmarked field
  bookmarks.insertVideo(woIsBookmarked);
}

export function removeBookmark(videoId) {
  bookmarks.removeById(videoId);
}
